<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header title="Шинэ агуулах үүсгэх"/>
      </el-col>
      <el-col :span="8" class="text-right">
        <el-button type="primary" @click="routerSet">Агуулахын бүтээгдэхүүнрүү очих</el-button>
      </el-col>
    </el-row>
    <el-form label-position="top" :model="warehouseForm" ref="warehouseForm" :rules="rules">
      <el-row v-loading="loading" :gutter="20">
        <el-col :span="12" :offset="2">
          <!-- form error template -->
            <div class="panel">
              <div class="panel-item">
                <el-form-item label="Монгол нэр" prop="name_mon">
                  <el-input v-model="warehouseForm.name_mon" placeholder="Дэд агуулах"></el-input>
                </el-form-item>
                <el-form-item label="Англи нэр" prop="name_eng">
                  <el-input v-model="warehouseForm.name_eng" placeholder="Sub warehouse"></el-input>
                </el-form-item>
                <el-form-item label="Хаяг" prop="address">
                  <el-input v-model="warehouseForm.address" placeholder="ХУД 3-р хороо Гоёо"></el-input>
                </el-form-item>
                <el-form-item label="Location">
                  <el-input v-model="warehouseForm.location" disabled></el-input>
                </el-form-item>
                <div class="" style="width: 100%; height: 400px; display:block">
                  <gmap-map
                    :center="googleMap.center"
                    :zoom="12"
                    :options="googleMap.options"
                    :draggable="false"
                    style="width:100%;  height: 100%; margin-top: 5px;"
                  >
                    <gmap-marker
                      :position="googleMap.location"
                      :clickable="true"
                      @position_changed="changeLocation($event)"
                      :draggable="true"/>
                  </gmap-map>
                </div>
              </div>
            </div>
        </el-col>
        <el-col :span="8">
          <div class="panel">
            <div class="panel-item">
              <el-form-item prop="supplier_id">
                <el-select placeholder="Нийлүүлэгч сонгох" v-model="warehouseForm.supplier_id" multiple>
                    <el-option
                    v-for="(supplier, indexSupplier) in suppliers"
                    :key="indexSupplier"
                    :label="supplier.supplier_monName"
                    :value="supplier.supplier_id">
                  </el-option>
                </el-select>
              </el-form-item>
              <header>Ажиллах цагийн хуваарь</header>
              <el-form-item :label="day.day" v-for="(day, indexDay) in week" :key="indexDay">
                <el-time-select
                  :disabled="day.is_weekend"
                  size="mini"
                  placeholder="Нээх цаг"
                  v-model="day.start_time"
                  :picker-options="{
                    start: '08:30',
                    step: '00:15',
                    end: '23:59'
                  }">
                </el-time-select>
                <el-time-select
                  class="ml5"
                  size="mini"
                  :disabled="day.is_weekend"
                  placeholder="Хаах цаг"
                  v-model="day.end_time"
                  :picker-options="{
                    start: '08:30',
                    step: '00:15',
                    end: '23:59'
                  }">
                </el-time-select>
                <el-tooltip class="item" effect="light" content="Амрах өдөр" placement="top">
                  <el-checkbox class="ml10" v-model="day.is_weekend" @change="isWeekend(day)"></el-checkbox>
                </el-tooltip>
              </el-form-item>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20" :offset="2">
          <el-row class="border-top pt20">
            <el-col :span="12">
              <el-button type="danger" @click="deleteWarehouse">Устгах</el-button>
            </el-col>
            <el-col :span="12">
              <div class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="updateWarehouse('warehouseForm')">Хадгалах</el-button>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/warehouse">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import CustomHeader from '../../../components/common/customHeader'
export default {
  name: 'warehouseUpdate',
  components: {
    CustomHeader
  },
  data () {
    return {
      suppliers: [],
      googleMap: {
        center: {
          lat: 47.9174,
          lng: 106.9137
        },
        location: {
          lat: 47.9174,
          lng: 106.9137
        },
        markerOptions: {
          size: { width: 100, height: 90, f: 'px', b: 'px' },
          scaledSize: { width: 45, height: 45, f: 'px', b: 'px' }
        },
        options: {
          scrollwheel: true,
          mapTypeControl: true,
          streetViewControl: false,
          minZoom: 4,
          maxZoom: 20,
          gestureHandling: 'greedy'
        }
      },
      loading: false,
      confirmLeaveForm: false,
      warehouseForm: {
        name_mon: '',
        name_eng: '',
        location: '47.9174, 106.9137',
        address: '',
        warehouse_id: '',
        supplier_id: [],
        work_time: {}
      },

      week: [
        {
          day: 'Даваа',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Мягмар',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Лхагва',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Пүрэв',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Баасан',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Бямба',
          is_weekend: false,
          start_time: null,
          end_time: null
        },
        {
          day: 'Ням',
          is_weekend: false,
          start_time: null,
          end_time: null
        }
      ],

      rules: {
        name_mon: [
          { required: true, message: 'Монгол нэрийг оруулна уу', trigger: 'blur' }
        ],
        name_eng: [
          { required: true, message: 'Англи нэрийг оруулна уу', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Хаягийн мэдээлэл оруулна уу', trigger: 'blur' }
        ],
        supplier_id: [
          { type: 'array', required: true, message: 'Нийлүүлэгчээ сонгоно уу?', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    if (this.$route.params.warehouse_id) {
      this.getOneWarehouse(this.$route.params.warehouse_id)
    }
    this.getSuppliers()
  },
  methods: {
    routerSet () {
      this.$router.push({ path: '/products', query: { warehouse: this.$route.params.warehouse_id } })
    },
    getSuppliers () {
      const query = '?search_text=' + '' + '&from=' + 0 + '&size=' + 100 + '&field=' + '' + '&sort=' + ''
      services.getSuppliers(query).then(response => {
        this.suppliers = response.data
      })
    },
    isWeekend (day) {
      if (day.is_weekend === true) {
        day.start_time = null
        day.end_time = null
      }
    },
    changeLocation (event) {
      this.warehouseForm.location = event.lat() + ', ' + event.lng()
    },
    getOneWarehouse (id) {
      this.loading = true
      services.getOneWarehouse(id).then(response => {
        if (response.status === 'success') {
          this.warehouseForm = response.data
          const location = this.warehouseForm.location.split(',')
          this.googleMap.location.lat = parseFloat(location[0])
          this.googleMap.location.lng = parseFloat(location[1])
          this.loading = false
          if (Object.prototype.hasOwnProperty.call(this.warehouseForm, 'work_time')) {
            this.week.forEach(day => {
              if (day.day === 'Даваа') {
                if (this.warehouseForm.work_time.monday.start_time === null || this.warehouseForm.work_time.monday.start_time === null) {
                  day.is_weekend = true
                }
                day.start_time = this.warehouseForm.work_time.monday.start_time
                day.end_time = this.warehouseForm.work_time.monday.end_time
              } else if (day.day === 'Мягмар') {
                if (this.warehouseForm.work_time.tuesday.start_time === null || this.warehouseForm.work_time.tuesday.start_time === null) {
                  day.is_weekend = true
                }
                day.start_time = this.warehouseForm.work_time.tuesday.start_time
                day.end_time = this.warehouseForm.work_time.tuesday.end_time
              } else if (day.day === 'Лхагва') {
                if (this.warehouseForm.work_time.wednesday.start_time === null || this.warehouseForm.work_time.wednesday.start_time === null) {
                  day.is_weekend = true
                }
                day.start_time = this.warehouseForm.work_time.wednesday.start_time
                day.end_time = this.warehouseForm.work_time.wednesday.end_time
              } else if (day.day === 'Пүрэв') {
                if (this.warehouseForm.work_time.thursday.start_time === null || this.warehouseForm.work_time.thursday.start_time === null) {
                  day.is_weekend = true
                }
                day.start_time = this.warehouseForm.work_time.thursday.start_time
                day.end_time = this.warehouseForm.work_time.thursday.end_time
              } else if (day.day === 'Баасан') {
                if (this.warehouseForm.work_time.friday.start_time === null || this.warehouseForm.work_time.friday.start_time === null) {
                  day.is_weekend = true
                }
                day.start_time = this.warehouseForm.work_time.friday.start_time
                day.end_time = this.warehouseForm.work_time.friday.end_time
              } else if (day.day === 'Бямба') {
                if (this.warehouseForm.work_time.saturday.start_time === null || this.warehouseForm.work_time.saturday.start_time === null) {
                  day.is_weekend = true
                }
                day.start_time = this.warehouseForm.work_time.saturday.start_time
                day.end_time = this.warehouseForm.work_time.saturday.end_time
              } else if (day.day === 'Ням') {
                if (this.warehouseForm.work_time.sunday.start_time === null || this.warehouseForm.work_time.sunday.start_time === null) {
                  day.is_weekend = true
                }
                day.start_time = this.warehouseForm.work_time.sunday.start_time
                day.end_time = this.warehouseForm.work_time.sunday.end_time
              }
            })
          }
        } else {
          this.alertReporter('Алдаа гарлаа', response.message, 'error')
          this.loading = false
        }
      })
    },
    deleteWarehouse () {
      this.loading = true
      const id = this.warehouseForm.warehouse_id
      this.$confirm('Та устгахдаа итгэлтэй байна уу?', 'Устгах', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Үгүй',
        type: 'warning'
      }).then(() => {
        services.deleteWarehouse(id).then(response => {
          if (response.status === 'success') {
            this.alertReporter('Амжилттай', response.message, 'success')
            this.loading = false
            this.$router.push({
              name: 'Warehouse'
            })
          } else {
            this.alertReporter('Алдаа', response.message, 'error')
            this.loading = false
          }
        })
      }).catch(() => {
        this.loading = false
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },
    updateWarehouse (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const body = this.warehouseForm
          this.loading = true
          services.updateWarehouse(body).then(response => {
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', response.message, 'success')
              this.loading = false
              this.$router.push({ name: 'Warehouse' })
            } else if (response.status === 'error') {
              this.alertReporter('Алдаа', response.message, 'error')
              this.loading = false
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>
